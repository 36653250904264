import React from 'react'
import styled, { keyframes } from 'styled-components'
import aboutPhoto from '../images/tahoe.jpg';

const animation = keyframes`
  0% {
      width: 0%;
      opacity: 0;
      transform: scale(1.2);
  }
  100% {
      width: 33%;
      opacity: 1;
      transform: scale(1.0);
  }
`

const Container = styled.div`
width: 100%;
position: fixed;

  @media only screen and (min-width: 375px) and (max-width: 812px) {
  position: relative;
  }
`

const Main = styled.div`
width: 0%;
float: right;
height: 100vh;
background: url(${aboutPhoto});
background-size: cover;
background-position: center center;
animation: ${animation} 2.3s forwards cubic-bezier(.12, 1, .25, 1);
animation-delay: 0.4s;

  @media only screen and (min-width: 375px) and (max-width: 812px) {
  width: 100%;
  height: 60vh;
  animation: none 0s;
  }
`

const SplitScreenRight = props => (
	<div>
  <Container>
    <Main>
		
    </Main>
  </Container>
  </div>
)

export default SplitScreenRight