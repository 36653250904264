import React from 'react'
import styled, { keyframes } from 'styled-components'

const text = styled.div`
font-family: 'Helvetica Neue', Arial; 
font-size: 16px; 
font-weight: bold;
`
const svg = styled.svg`
float: right;
height: 540px;
animation: rotate 10 infinite linear;
`
// left: 0em; 
// top: 0em;

const rotate = keyframes`
  0% {
      transform: rotate(360deg);
  }
  100% {
      transform: rotate(0deg);
  }
`

const Circle = (props) => (
  <svg x="0px" y="0px" width="250px" height="250px" viewBox="0 0 300 300">
    <defs>
       <path id="circlePath" d=" M 150, 150 m -60, 0 a 60,60 0 0,1 120,0 a 60,60 0 0,1 -120,0 "/>
    </defs>
    <circle cx="150" cy="100" r="75" fill="none"/>
    <g>
        <use href="#circlePath" fill="none"/>
        <text fill="#000">
            <textPath href="#circlePath">Design & Dev in React by Alex Trimoulet</textPath>
        </text>
    </g>
  </svg>
)

export default Circle

// http://ahrengot.com/tutorials/greensock-javascript-animation
