import React from 'react'
import styled, { keyframes } from 'styled-components'
import Circle from '../components/circle.js'
import BackgroundTextSplit from '../components/backgroundTextSplit.js'
import Link from 'gatsby-link';

const animation = keyframes`
  0% {
      opacity: 0;
      transform: translateY(-50px);
  }
  100% {
      opacity: 1;
      transform: translateY(0px);
  }
`

const Main = styled.div`
width: 57%;
float: left;
background: #fff;

  @media only screen and (min-width: 365px) and (max-width: 812px) {
  width: 100%;
  display: block;
  margin-bottom: 3em;
  height: 100%;
  }
`
/*
  &:before {
  content:'';
  height: 0vh;
  width: 100vw;
  background-color: #f8f8f8;
  position:absolute;
  bottom:0;
  z-index: 10000;  
  animation-name: slideUp;
  animation-duration: 1s;
  }
*/

const TextContainer = styled.div`
padding-top: 11%;
padding-left: 17%;
opacity: 0;
animation: ${animation} 3s forwards cubic-bezier(0.2, 0.8, 0.2, 1.0);
animation-delay: 0.2s;

@media only screen and (min-width: 375px) and (max-width: 812px) {
padding-top: 11%;
padding-left: 10%;
}
`

const Title = styled.div`
font-size: 2em;
font-weight: 700;
margin-bottom: 1em;
`
const Paragraph = styled.div`
width: 80%;
font-size: 0.9em;
line-height: 1.8;

`
const Subtitle = styled.div`
font-size: 1.2em;
font-weight: 700;
margin: 2em 0em 1em 0em;
`
const LinksList = styled.div`

`
const LearnMoreLink = styled.div`
margin-bottom: 0.2em; 
`

const DevelopedBy = styled.div`
  margin-top: 3em;
  color: #A1A5AA;
  font-weight: 400;
  font-size: 0.9em;
`

const SplitScreenLeft = props => (
	<div>
    <Main>
    <TextContainer>
  		 <Subtitle className="Subtitle">Bio</Subtitle>
      <Paragraph>
      		I'm a curious product designer passionate by design, tech and society. I value problem-solving, collaboration, proactivity, transparency, outcomes, boldness. I work on end-to-end product design, from the early stages of the discovery to the late stages of the delivery, aiming to build the best products to empower people at scale. <br/>
          <br/>
          I work as Senior Product Designer at <a href="https://livestorm.co/" target="_blank">Livestorm</a> since Oct. 2021. Before that, I had multiple product design experiences at big and small tech companies in San Francisco, California and Paris, France. During the pandemic, I left the US and moved to France to work at <a href="https://doctolib.com/" target="_blank">Doctolib</a> on products used by 200k+ healthcare professionals and 50M patients. Before COVID, I was in San Francisco to work on the Smart Remote product at <a href="https://www.globenewswire.com/en/news-release/2020/11/05/2120942/11142/en/Qorvo-Acquires-UWB-Software-Provider-7Hugs-Labs-S-A-S.html" target="_blank">Sevenhugs (now Qorvo)</a>. Prior to those experiences, I worked at <a href="https://agorize.com/" target="_blank">Agorize</a>, where I led the design of the b2b and b2c products as well as the design system, at <a href="https://500.co/startup/sourceeasy/" target="_blank">Sourceeasy</a>, where I was PM and was responsible for the development of new features on our SaaS product, and at <a href="https://www.producthunt.com/posts/triber" target="_blank">Triber</a>, an early-stage startup where I was the first in-house designer.<br/>
          <br/>
          I'm also passionate about <a href="/music" target="_blank">music production</a>, sports and travel. 10 years ago, I started producing electronic music and writing album critics & events reports for webzines. I then started to build my own website, and since then I never stopped building digital products.

    	
      <Subtitle className="Subtitle"></Subtitle> 	
    	<LinksList>
	       <LearnMoreLink>
	       		<p className="SocialLinks">
              Find me on
              <a id="linkedin" href="https://www.linkedin.com/in/alexandretrimoulet" target="_blank"> LinkedIn</a>, 
              <a id="medium" href="https://medium.com/@AlexTrimoulet" target="_blank"> Medium</a>, 
              <a id="twitter" href="https://twitter.com/AlexTrimoulet" target="_blank"> Twitter</a>,
              <a id="soundcloud" href="https://soundcloud.com/osphoz/" target="_blank"> SoundCloud</a>,
              <a id="dribbble" href="https://dribbble.com/alexandret" target="_blank"> Dribbble</a>, 
              <a id="producthunt" href="https://www.producthunt.com/@alextrimoulet" target="_blank"> Product Hunt</a>,  & 
              <a id="instagram" href="https://instagram.com/alextrmlt" target="_blank"> Instagram</a>.
            </p>
	       </LearnMoreLink>
	    </LinksList>

        </Paragraph>
	    
      <DevelopedBy><p>Portfolio designed & developed in React by Alex Trimoulet 💪🏻 <br />
        © All rights reserved 2022</p></DevelopedBy>
    </TextContainer>
    </Main>
    </div>
)

export default SplitScreenLeft