import React, { Component } from 'react'
import styled from 'styled-components'
import SplitScreenLeft from '../components/splitScreenLeft'
import BackgroundTextSplit from '../components/backgroundTextSplit.js'
import SplitScreenRight from '../components/splitScreenRight';

import Layout from "../components/layout"

export default class About extends Component {
	render () {
		return (
		<Layout>
			<div>
				<BackgroundTextSplit text="About" />
				<SplitScreenRight/>
				<SplitScreenLeft/>
			</div>
		</Layout>
		)
	}
}